import { BaseButton } from 'components/elements';
import { JOININFOCHANGE2 } from 'constants';
import { NEXT } from 'constants';
import { JOININFOCHANGE } from 'constants';
import { NEEDHOME } from 'constants';
import { COMEPETHOME } from 'constants';
import { AGENT } from 'constants/Mobile';
import { useAuth } from 'hooks/AuthContext';
import { useEffect } from 'react';

export const JoinSettingComplete = ({ onMoveStep, token, userIdx, common }) => {
    const { login } = useAuth();

    const fnCheckOS = () => {
        if (common.agentType === AGENT.ANDROID_APP && window.pethome?.signup) {
            // 안드로이드 앱일 때
            window.pethome.signup();
        }
        onMoveStep();
    };

    useEffect(() => {
        login({ token, user_idx: userIdx });
    }, [token, userIdx]);

    return (
        <>
            <div className="wrap wrap-join">
                <div className="member">
                    <div className="join">
                        <div className="step-6">
                            <h1 className="need-home-text join-page">
                                {COMEPETHOME}
                                <p>{NEEDHOME}</p>
                            </h1>
                            <div className="map-wrap">
                                <div className="bookmark-box">
                                    <div className="bookmark">
                                        <p>{JOININFOCHANGE}</p>
                                        <p>{JOININFOCHANGE2}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-wrap">
                    <BaseButton label={NEXT} onClick={fnCheckOS} />
                </div>
            </div>
        </>
    );
};
