export const ANDROID_APP = 'compathome://';
export const ANDROID_APP_LINK = `compathome://compathomelink`;

export const IOS_APP = 'compathome://';
export const IOS_APP_LINK = `compathome://compathomelink`;

export const ACCESS_TYPE = {
    CAMERA: 1001,
    LOCATION: 1002,
    NOTIFICATION: 1003,
    CONTACT: 1004,
    IOS_ALBUMN: 1005,
};

export const AGENT = {
    ANDROID_APP: 'ANDROID_APP',
    ANDROID_WEB: 'ANDROID_WEB',
    IOS_APP: 'IOS_APP',
    IOS_WEB: 'IOS_WEB',
    PC: 'PC',
};
