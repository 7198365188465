import { Image } from 'antd-mobile';
import styled from 'styled-components';

import homeOff from 'assets/images/icon-home-off.svg';
import homeOn from 'assets/images/icon-home-on.svg';
// import homeTextOff from 'assets/images/text-home-off.svg';
// import homeTextOn from 'assets/images/text-home-on.svg';
import commuOff from 'assets/images/icon-community-off.svg';
import commuOn from 'assets/images/icon-community-on.svg';
// import commuTextOff from 'assets/images/text-community-off.svg';
// import commuTextOn from 'assets/images/text-community-on.svg';
import walletOff from 'assets/images/icon-wallet-off.svg';
import walletOn from 'assets/images/icon-wallet-on.svg';
// import walletTextOff from 'assets/images/text-wallet-off.svg';
// import walletTextOn from 'assets/images/text-wallet-on.svg';
import moreOff from 'assets/images/icon-more-off.svg';
import moreOn from 'assets/images/icon-more-on.svg';
// import moreTextOff from 'assets/images/text-more-off.svg';
// import moreTextOn from 'assets/images/text-more-on.svg';
import { MAIN, COMMUNITY, NOTE, MORE } from 'constants';

// const FooterImageWrapper = styled(Image)`
//   display: flex;
//   justify-content: center;
//   width: 5.2rem;
//   height: 2.4rem;
//   img {
//     width: 100%;
//     height: 100%;
//   }

// `;
// const FooterImageTextWrapper = styled(Image)`
//   // width: 4.4rem;
//   // height: 2rem;
//   font-size: 1.2em;
// `;

const IMG_WIDTH = '2.4rem';
const IMG_HEIGHT = '2.4rem';
// const TEXT_WIDTH = '4.4rem';
// const TEXT_HEIGHT = '2rem';
const TEXT_FONTSIZE = '1.2rem';
const TEXT_FONTCOLOR = '#9E9E9E';
const TEXT_FONTCOLOR_ACTIVE = '#40DF9F';
const IMG_STYLE = {
    '--width': IMG_WIDTH,
    width: IMG_WIDTH,
    '--height': IMG_HEIGHT,
    height: IMG_HEIGHT,
};
const TEXT_STYLE = {
    // '--width': TEXT_WIDTH,
    // width: TEXT_WIDTH,
    // '--height': TEXT_HEIGHT,
    // height: TEXT_HEIGHT,
    fontSize: TEXT_FONTSIZE,
    color: TEXT_FONTCOLOR,
};
const TEXT_STYLE_ACTIVE = {
    fontSize: TEXT_FONTSIZE,
    color: TEXT_FONTCOLOR_ACTIVE,
};
export function ImageHomeOn({ onClick }) {
    return (
        <div style={IMG_STYLE} onClick={onClick}>
            <img alt={MAIN} src={homeOn} />
        </div>
    );
}
export function ImageHomeTextOn({ onClick }) {
    return (
        <div style={TEXT_STYLE_ACTIVE} onClick={onClick}>
            {MAIN}
        </div>
    );
}
export function ImageHomeOff({ onClick }) {
    return (
        <div style={IMG_STYLE} onClick={onClick}>
            <img alt={MAIN} src={homeOff} />
        </div>
    );
}
export function ImageHomeTextOff({ onClick }) {
    return (
        <div style={TEXT_STYLE} onClick={onClick}>
            {MAIN}
        </div>
    );
}
export function ImageCommuOn({ onClick }) {
    return (
        <div style={IMG_STYLE} onClick={onClick}>
            <img alt={COMMUNITY} src={commuOn} />
        </div>
    );
}
export function ImageCommuTextOn({ onClick }) {
    return (
        <div style={TEXT_STYLE_ACTIVE} onClick={onClick}>
            {COMMUNITY}
        </div>
    );
}
export function ImageCommuOff({ onClick }) {
    return (
        <div style={IMG_STYLE} onClick={onClick}>
            <img alt={COMMUNITY} src={commuOff} />
        </div>
    );
}
export function ImageCommuTextOff({ onClick }) {
    return (
        <div style={TEXT_STYLE} onClick={onClick}>
            {COMMUNITY}
        </div>
    );
}
export function ImageWalletOn({ onClick }) {
    return (
        <div style={IMG_STYLE} onClick={onClick}>
            <img alt={NOTE} src={walletOn} />
        </div>
    );
}
export function ImageWalletTextOn({ onClick }) {
    return (
        <div style={TEXT_STYLE_ACTIVE} onClick={onClick}>
            {NOTE}
        </div>
    );
}
export function ImageWalletOff({ onClick }) {
    return (
        <div style={IMG_STYLE} onClick={onClick}>
            <img alt={NOTE} src={walletOff} />
        </div>
    );
}
export function ImageWalletTextOff({ onClick }) {
    return (
        <div style={TEXT_STYLE} onClick={onClick}>
            {NOTE}
        </div>
    );
}
export function ImageMoreOn({ onClick }) {
    return (
        <div style={IMG_STYLE} onClick={onClick}>
            <img alt={MORE} src={moreOn} />
        </div>
    );
}
export function ImageMoreTextOn({ onClick }) {
    return (
        <div style={TEXT_STYLE_ACTIVE} onClick={onClick}>
            {MORE}
        </div>
    );
}
export function ImageMoreOff({ onClick }) {
    return (
        <div style={IMG_STYLE} onClick={onClick}>
            <img alt={MORE} src={moreOff} />
        </div>
    );
}
export function ImageMoreTextOff({ onClick }) {
    return (
        <div style={TEXT_STYLE} onClick={onClick}>
            {MORE}
        </div>
    );
}
