import { Image } from 'antd-mobile';

import logo from 'assets/icons/logo.svg';
import bigLogo from 'assets/icons/logo-big.svg';
import search from 'assets/icons/search.svg';
import alarm from 'assets/icons/alarm.svg';
import alarmActive from 'assets/icons/icon-alarm-active.svg';
import note from 'assets/icons/note.svg';
import close from 'assets/icons/close.svg';
import arrowUp from 'assets/icons/arrow-up.svg';
import setting from 'assets/images/icon-setting.svg';
import arrowLeftWhite from 'assets/icons/arrow-left-white.svg';
import arrowRightWhite from 'assets/icons/arrow-right-white.svg';
import arrowLeft from 'assets/icons/arrow-left.svg';
import arrowRight from 'assets/icons/arrow-right.svg';
import grayArrowDown from 'assets/icons/ico_arrow_down_gray_16.svg';
import grayArrowUp from 'assets/icons/ico_arrow_up_gray_16.svg';
import keyArrowUp from 'assets/icons/keyboard_arrow_up.svg';
import keyArrowDown from 'assets/icons/keyboard_arrow_down.svg';
import arrowBottom from 'assets/images/icon-arrow-bottom.svg';
import arrowBottomActive from 'assets/images/icon-arrow-bottom-active.svg';
import arrowBottomValue from 'assets/images/icon-arrow-bottom-value.svg';
import back from 'assets/images/icon-back.svg';
import lock from 'assets/images/icon-lock.svg';
import unlock from 'assets/images/icon-unlock.svg';
import view from 'assets/images/icon-eye.svg';
import reply from 'assets/icons/icon-reply.svg';
import like from 'assets/icons/icon-good.svg';
import likeActive from 'assets/icons/icon-good-active.svg';
import question from 'assets/images/icon-question.svg';
import question2 from 'assets/images/icon-question2.svg';
import searchOff from 'assets/images/icon-search-off.svg';
import searchClose from 'assets/images/icon-search-close.svg';
import iconClose from 'assets/images/header-icon-close.svg';
import iconSearch from 'assets/images/header-icon-search.svg';
import filter from 'assets/images/icon-filter.svg';

import close2 from 'assets/images/icon-close.svg';
import close3 from 'assets/images/icon-close-circle.svg';
import add from 'assets/images/add-2.svg';

import level1 from 'assets/images/grade1.svg';
import level2 from 'assets/images/grade2.svg';
import level3 from 'assets/images/grade3.svg';
import level4 from 'assets/images/grade4.svg';
import level5 from 'assets/images/grade5.svg';
import level6 from 'assets/images/grade6.svg'; // 기고가
import level7 from 'assets/images/grade7.svg'; // 보호소
import level8 from 'assets/images/grade8.svg'; // 스텝
import level9 from 'assets/images/grade9.svg'; // 고인물

import visit from 'assets/images/profile-icon-visit.svg';
import write from 'assets/images/profile-icon-write.svg';
import replyProfile from 'assets/images/profile-icon-reply.svg';
import neighbor from 'assets/images/profile-icon-neighbor.svg';

import chart from 'assets/images/ico_account_book_36.svg';
import exclamation from 'assets/images/icon-exclamation.svg';
import photo from 'assets/images/icon-photo.svg';
import photoNotAllowed from 'assets/images/icon-20.svg';
import help from 'assets/icons/help.svg';
import moreReply from 'assets/images/icon-more-vertical-dot.svg';
import moreReReply from 'assets/images/arrow-bottom-small.svg';

import chpLogo from 'assets/images/icon_cph_logo.svg'; // MyPet모달에서 '전체'일때 발바닥 이미지
export const chpLogoSrc = '/assets/images/icon_cph_logo.svg'; // MyPet모달에서 '전체'일때 발바닥 이미지

export function IconLogo({ onClick }) {
    return (
        <img
            src={logo}
            style={{ width: 32, height: 32 }}
            onClick={onClick}
            alt="logo"
        />
    );
}
export function IconBigLogo({ onClick }) {
    return <Image src={bigLogo} onClick={onClick} />;
}
export function IconSearch({ onClick }) {
    return <Image src={search} width={32} height={32} onClick={onClick} />;
}
export function IconAlarm({ onClick }) {
    return <Image src={alarm} width={32} height={32} onClick={onClick} />;
}
export function IconAlarmActive({ onClick }) {
    return <Image src={alarmActive} width={32} height={32} onClick={onClick} />;
}
export function IconNote({ onClick }) {
    return <Image src={note} width={32} height={32} onClick={onClick} />;
}
export function IconSetting({ onClick }) {
    return <Image src={setting} width={32} height={32} onClick={onClick} />;
}
export function IconClose({ onClick }) {
    return <Image src={close} width={14} height={14} onClick={onClick} />;
}
export function IconArrowUp({ width = 12, height = 12 }) {
    return <Image src={arrowUp} width={width} height={height} />;
}
export function IconArrowLeftWhite({ width = 24, height = 24 }) {
    return <Image src={arrowLeftWhite} width={width} height={height} />;
}
export function IconArrowRightWhite({ width = 24, height = 24 }) {
    return <Image src={arrowRightWhite} width={width} height={height} />;
}
export function IconArrowLeft({ width = 24, height = 24, onClick }) {
    return (
        <Image
            src={arrowLeft}
            width={width}
            height={height}
            onClick={onClick}
        />
    );
}
export function IconArrowRight({ width = 24, height = 24, onClick }) {
    return (
        <Image
            src={arrowRight}
            width={width}
            height={height}
            onClick={onClick}
        />
    );
}
export function IconGrayArrowDown({ isShow, onClick }) {
    const style = {
        transform: !isShow ? 'rotate(0deg)' : 'rotate(180deg)',
    };

    return (
        <img src={grayArrowDown} onClick={onClick} alt="펴기" style={style} />
    );
}
// export function IconGrayArrowUp({ onClick }) {
//   return (
//     <img  src={grayArrowUp} onClick={onClick} alt="접기" />
//   );
// }
export function IconKeyArrowUp({ width = 24, height = 24 }) {
    return <Image src={keyArrowUp} width={width} height={height} />;
}
export function IconKeyArrowDown({ width = 24, height = 24 }) {
    return <Image src={keyArrowDown} width={width} height={height} />;
}
export function IconArrowBottom() {
    return <img src={arrowBottom} alt="" />;
}
export function IconArrowBottomActive() {
    return <img src={arrowBottomActive} alt="" />;
}
export function IconArrowBottomValue() {
    return <img src={arrowBottomValue} alt="" />;
}
export function IconBack({ width = 24, height = 24, altText = '' }) {
    return <Image src={back} width={width} height={height} alt={altText} />;
}
export function IconLock({ width = 24, height = 24, altText = '' }) {
    return <Image src={lock} width={width} height={height} alt={altText} />;
}
export function IconUnLock({ width = 24, height = 24, altText = '' }) {
    return <Image src={unlock} width={width} height={height} alt={altText} />;
}
export function IconLevel({ level, altText = '' }) {
    let imgSrc;
    switch (Number(level)) {
        case 1:
            imgSrc = level1;
            break;
        case 2:
            imgSrc = level2;
            break;
        case 3:
            imgSrc = level3;
            break;
        case 4:
            imgSrc = level4;
            break;
        case 5:
            imgSrc = level5;
            break;
        case 6:
            imgSrc = level6;
            break;
        case 7:
            imgSrc = level7;
            break;
        case 8:
            imgSrc = level8;
            break;
        case 9:
            imgSrc = level9;
            break;
        default:
            break;
    }
    return <img src={imgSrc} alt={altText} />;
}
export function IconView({ altText }) {
    return <img src={view} alt={altText} />;
}
export function IconVisit({ altText }) {
    return <img src={visit} alt={altText} />;
}
export function IconWrite({ altText }) {
    return <img src={write} alt={altText} />;
}
export function IconNeighbor({ altText }) {
    return <img src={neighbor} alt={altText} />;
}
export function IconReply({ altText }) {
    return <img src={reply} alt={altText} />;
}
export function IconReplyProfile({ altText }) {
    return <img src={replyProfile} alt={altText} />;
}
export function IconLike({ altText }) {
    return <img src={like} alt={altText} />;
}
export function IconQuestion({ altText }) {
    return <img src={question} alt={altText} />;
}
export function IconQuestion2({ altText }) {
    return <img src={question2} alt={altText} />;
}
export function IconFilter({ altText }) {
    return <img src={filter} alt={altText} />;
}
export function IconChart({ altText, onClick }) {
    return (
        <img
            src={chart}
            alt={altText}
            onClick={() => {
                if (onClick) onClick();
            }}
        />
    );
}
export function IconExclamation({ width = 24, height = 24, altText = '' }) {
    return (
        <Image src={exclamation} width={width} height={height} alt={altText} />
    );
}
export function IconPhoto({ width = 24, height = 24, altText = '' }) {
    return <Image src={photo} width={width} height={height} alt={altText} />;
}
export function IconPhotoNotAllowed({ width = 24, height = 24, altText = '' }) {
    return (
        <Image
            src={photoNotAllowed}
            width={width}
            height={height}
            alt={altText}
        />
    );
}
export function IconCphLogo({ altText, onClick }) {
    return (
        <img
            src={chpLogo}
            alt={altText}
            onClick={() => {
                if (onClick) onClick();
            }}
        />
    );
}
export function IconHelp() {
    return <Image src={help} width={11.67} height={11.67} />;
}
export function IconMore({ altText }) {
    return <Image src={moreReply} alt={altText} />;
}
export function IconMoreReReply({ width = 12, height = 12, altText }) {
    return (
        <Image src={moreReReply} width={width} height={height} alt={altText} />
    );
}
export function IconCloseSub({ altText }) {
    return <Image src={close2} alt={altText} />;
}
export function IconCloseCircle({ altText }) {
    return <Image src={close3} alt={altText} />;
}
export function IconAdd({ altText }) {
    return <Image src={add} alt={altText} />;
}
export function IconSearchOff({ altText }) {
    return <Image src={searchOff} alt={altText} />;
}
export function IconSearchClose({ altText }) {
    return <Image src={searchClose} alt={altText} />;
}
export function IconClose3({ altText }) {
    return <Image src={iconClose} alt={altText} />;
}
export function IconSearch2({ altText }) {
    return <Image src={iconSearch} alt={altText} />;
}
export function IconLikeActive({ altText }) {
    return <Image src={likeActive} alt={altText} />;
}
