import { type } from '@testing-library/user-event/dist/type';
import { Map } from 'immutable';

export const TYPES = {
    SET_COMMUNITY_LIST_DATA: 'SET_COMMUNITY_LIST_DATA',

    SET_COMMUNITY_PAGE_NO: 'SET_COMMUNITY_PAGE_NO',

    SET_COMMUNITY_PAGE_PARAMS: 'SET_COMMUNITY_PAGE_PARAMS',
};

const initState = {
    communityListData: {
        listData: [],
        page_no: 0,
        page_params: [],
    },
};

export default function ListReducer(state = initState, action) {
    switch (action.type) {
        case TYPES.SET_COMMUNITY_LIST_DATA:
            let newPageNo;
            if (!action.payload.length) {
                newPageNo = 1;
            } else {
                newPageNo = Math.floor(action.payload.length / 10);
            }

            return {
                ...state,
                communityListData: {
                    ...state.communityListData,
                    listData: action.payload,
                    page_no: newPageNo,
                },
            };

        // 페이지 넘버
        case TYPES.SET_COMMUNITY_PAGE_NO:
            return {
                ...state,
                communityListData: {
                    ...state.communityListData,
                    page_no: action.payload,
                },
            };

        case TYPES.SET_COMMUNITY_PAGE_PARAMS:
            return {
                ...state,
                communityListData: {
                    ...state.communityListData,
                    page_params: action.payload,
                },
            };

        default:
            return state;
    }
}

export const actions = {
    setListData: (data) => ({
        type: TYPES.SET_COMMUNITY_LIST_DATA,
        payload: data,
    }),

    setPageNo: (data) => ({ type: TYPES.SET_COMMUNITY_PAGE_NO, payload: data }),

    setPageParams: (data) => ({
        type: TYPES.SET_COMMUNITY_PAGE_PARAMS,
        payload: data,
    }),
};
