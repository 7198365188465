//import { Popup } from 'antd-mobile';
import { useEffect } from 'react';
import { BottomPopUp } from './BottomPopUp';
import {
    BaseButton,
    BaseImageButton,
    BaseTextButton,
} from 'components/elements';
import { useState } from 'react';
import { getAnimalListApi } from 'api/member';
import { CHECK } from 'constants';

// mode가 single인 경우 defaultData = {code: '', name: ''}
export const AnimalModal = ({
    show,
    title,
    desc = undefined,
    mode,
    defaultData = undefined,
    onOk,
    onClick,
    onClose,
}) => {
    const [dataList, setDataList] = useState([]);
    const [choice, setChoice] = useState({
        code: '',
        name: '',
    }); //1개선택

    const fnHandleClick = ({ code, name }) => {
        setChoice({ code, name });
        //1개선택
        // if (choice.code === code) setChoice({ code: '', name: '' }); //선택해제
        // else setChoice({ code, name });
    };

    const fnHandleClose = () => {
        if (onClose) onClose();
    };

    // 24.08.01 mcb 요청에 의한 모달내 확인버튼 일괄 삭제
    // const fnHandleOk = () => {
    //   if (onOk) onOk(choice)
    // };

    const fnHandleMaskClick = () => {
        if (onClose) onClose();
    };

    useEffect(() => {
        const fnGetAnimalList = async () => {
            try {
                const res = await getAnimalListApi();
                const { data_list } = res?.data;
                const animalList = data_list?.reduce((acc, current) => {
                    const key = current.animal_group_name;

                    if (!acc[key]) {
                        acc[key] = [];
                    }

                    acc[key].push(current);
                    return acc;
                }, {});

                setDataList(animalList);
            } catch (err) {
                console.error(err);
            }
        };

        fnGetAnimalList();
    }, []);

    useEffect(() => {
        if (!defaultData) return;
        const { code, name } = defaultData;
        fnHandleClick({ code, name });
    }, [defaultData]);

    return (
        // <Popup
        //     visible={show}
        //     mask={mask}
        //     position={position}
        //     bodyStyle={{
        //       ...bodyStyle,
        //       minHeight: '27rem',
        //     }}
        //   >
        <BottomPopUp show={show} onMaskClick={fnHandleMaskClick}>
            <div className="popup-bottom">
                <div className="popup-wrap animal-modal">
                    <div className="popup-head popup-close">
                        <strong>{title}</strong>
                        <BaseImageButton
                            imageSrc={'/assets/images/icon-close.svg'}
                            altText={'닫기'}
                            onClick={fnHandleClose}
                        />
                        {desc && <p className="popup-title-sub">{desc}</p>}
                    </div>
                    <div className="popup-content">
                        <div className="checkbox-list">
                            <ul>
                                {Object.keys(dataList)?.map(
                                    (groupName, idx) => (
                                        <li key={`animal-p-${idx}`}>
                                            <p className="title-text">
                                                {groupName}
                                            </p>
                                            <div className="checkbox-button-wrap">
                                                {dataList[groupName]?.map(
                                                    (item, subIdx) => (
                                                        <div
                                                            className="checkbox-button"
                                                            key={`animal-s-${idx}${subIdx}`}
                                                        >
                                                            {/* <input checked={choice === item.animal_code ? true : false} type="checkbox" name="check-use" className="check" id={`check-use-${idx}-${subIdx}`}/> */}
                                                            <input
                                                                type="checkbox"
                                                                checked={
                                                                    choice.code ===
                                                                    item.animal_code
                                                                        ? true
                                                                        : false
                                                                }
                                                                name="check-use"
                                                                className="check"
                                                                onChange={(
                                                                    e,
                                                                ) => {
                                                                    // console.log(e)
                                                                }}
                                                            />
                                                            <label
                                                                onClick={() => {
                                                                    onOk({
                                                                        code: item.animal_code,
                                                                        name: item.animal_name,
                                                                    });
                                                                }}
                                                            >
                                                                {
                                                                    item.animal_name
                                                                }
                                                            </label>
                                                        </div>
                                                    ),
                                                )}
                                            </div>
                                        </li>
                                    ),
                                )}
                            </ul>
                        </div>
                    </div>
                    {/* 24.08.01 mcb 요청에 의한 모달내 확인버튼 일괄 삭제 */}
                    {/* <div className="popup-button-wrap">
            <BaseTextButton
              label={CHECK}
              onClick={fnHandleOk}
              className={
                choice.code === '' &&
                choices?.map((item) => item.code).length === 0
                  ? 'disabled'
                  : 'active'
              }
              disabled={
                choice.code === '' &&
                choices?.map((item) => item.code).length === 0
                  ? true
                  : false
              }
            />
          </div> */}
                </div>
            </div>
        </BottomPopUp>
        // </Popup>
    );
};
