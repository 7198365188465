import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { callApi } from '../api';
import { auth } from 'data/auth';
import { TYPES } from 'store/auth-reducer';

function* postAuth({ param }) {
    try {
        let result;

        result = auth;

        // ({ result } = yield call(callApi, { url: `/xxx/${param}`, }));

        yield put({
            type: TYPES.POST_AUTH_SUCCESS,
            prod: result,
        });
    } catch (err) {}
}
function* watchPostAuth() {
    yield takeLatest(TYPES.POST_AUTH, postAuth);
}
export default function* authSaga() {
    yield all([fork(watchPostAuth)]);
}
