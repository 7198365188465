import styled from 'styled-components';

const HandleWrapper = styled.div`
    height: 2.4rem;
    border-radius: 1.2rem 1.2rem 0 0;
    position: relative;
    padding: 1.2rem 0 0.4rem 0;
    /* background: red; */
`;

const Handle = styled.div`
    width: 4rem;
    height: 0.4rem;
    border-radius: 0.2rem;
    background-color: #dee2e6;
    margin: auto;
`;
const BottomSheetHeader = () => {
    return (
        <HandleWrapper>
            <Handle />
        </HandleWrapper>
    );
};

export default BottomSheetHeader;
