import { BaseTextButton, FloatingButton } from 'components/elements';
import { SubjectModal } from './SubjectModal';
import { useAuth } from 'hooks/AuthContext';
import { URL_COMMUNITY_ADD, URL_LOGIN } from 'routes/RouteUrl';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { getCommunityInfoAPI } from 'api/community';
import { useMemberPlan } from 'hooks/useMemberPlan';
import { AlertModal } from './AlertModal';
import { CHECK, LIMITUSER, LIMITUSER2 } from 'constants';
import { LIMITUSER3 } from 'constants';

export const FloatingWithSubjectModal = () => {
    const { user } = useAuth();
    const userIdx = user?.user_idx;
    const { memberPlan } = useMemberPlan({ userIdx });
    const navigate = useNavigate();

    const [openModal, setOpenModal] = useState({
        openSubjectModal: false,
        openCompleteModal: false,
        completeModalTitle: null,
        completeModalSubTitle: null,
    });
    const [filterSubjectList, setFilterSubjectList] = useState([]);

    // 글쓰기 주제 선택 모달 열기
    const fnOpenSubjectModal = () => {
        if (userIdx && memberPlan?.status !== 0) {
            const fnGetCategoryList = async () => {
                let params = [];
                params.push(`page_no=1`);

                try {
                    const res = await getCommunityInfoAPI({ params });
                    const { communityCategoryList } = res?.data?.data_info;
                    const subList = communityCategoryList?.filter(
                        (item) => item.parent_idx !== 0,
                    );
                    const mainList = communityCategoryList?.filter(
                        (item) => item.parent_idx === 0,
                    );
                    const dataList = mainList?.map((item) => {
                        return {
                            ...item,
                            subList: subList?.filter(
                                (subItem) => item.idx === subItem.parent_idx,
                            ),
                        };
                    });

                    setFilterSubjectList(dataList);
                } catch (err) {
                    console.error(err);
                } finally {
                    setOpenModal((prev) => ({
                        ...prev,
                        openSubjectModal: true,
                    }));
                }
            };

            fnGetCategoryList();
        } else if (!userIdx) navigate(URL_LOGIN);
        else if (userIdx && memberPlan?.status === 0) {
            setOpenModal((prev) => ({
                ...prev,
                openCompleteModal: true,
                completeModalTitle: LIMITUSER,
                completeModalSubTitle: (
                    <div>
                        {LIMITUSER2}
                        <br />
                        {LIMITUSER3}
                    </div>
                ),
            }));
        }
    };

    // 글쓰기 주제 선택 모달 닫기
    const fncloseSubjectModal = () => {
        setOpenModal((prev) => ({ ...prev, openSubjectModal: false }));
    };

    const fnCloseAlertModal = () => {
        setOpenModal((prev) => ({ ...prev, openCompleteModal: false }));
    };

    // 글쓰기 주제 선택 후
    const fnHandleSubject = (value) => {
        navigate(URL_COMMUNITY_ADD, { state: { selectedSubjectIdx: value } });
    };

    return (
        <>
            <div className="writing-button-wrap">
                <FloatingButton fnClickFloatingButton={fnOpenSubjectModal} />
            </div>

            {/* 게시글 주제 선택 모달 */}
            <SubjectModal
                show={openModal?.openSubjectModal}
                onOk={fnHandleSubject}
                onClose={fncloseSubjectModal}
                dataList={filterSubjectList}
            />

            {/* 완료 모달 */}
            <AlertModal
                show={openModal?.openCompleteModal}
                label={openModal?.completeModalTitle}
                subtitle={openModal?.completeModalSubTitle}
                footer={
                    <BaseTextButton
                        className="point-g"
                        label={CHECK}
                        onClick={fnCloseAlertModal}
                    />
                }
            />
        </>
    );
};
