import { postMemberAgreeTermsAPI } from 'api/join';
import { BaseButton, BaseInput, BaseLink } from 'components/elements';
import { AGREEMARKETING } from 'constants';
import { AGREEINFO } from 'constants';
import { AGREESERVICETERMS } from 'constants';
import { MOREAGE } from 'constants';
import {
    SEEALL,
    PLEASEAGREETERMS,
    NEXT,
    INFOANDSERVICE,
    GOODKEEP,
    SELECT,
    REQUIRE,
    ALLAGREE,
} from 'constants';
import { useState } from 'react';
import {
    URL_MORE_SERVICE_DETAIL_MARKETING,
    URL_MORE_SERVICE_DETAIL_PERSONAL,
    URL_MORE_SERVICE_DETAIL_USE,
} from 'routes/RouteUrl';
import { useErrorHandler } from 'hooks/useErrorHandler';
import { BaseLayout } from 'components/layouts';

export const TermsAgreeModal = ({ onClick, userIdx }) => {
    const [allAgree, setAllAgree] = useState(true);
    const [agree, setAgree] = useState({
        agreeAge: true,
        agreeService: true,
        agreeInfo: true,
        agreeMarketing: true,
    });
    const [errorData, setErrorData] = useState(null);
    const { ErrorModal } = useErrorHandler({ errorData });

    const fnChangeAllAgree = (e) => {
        setAllAgree(e);
        setAgree({
            agreeAge: e,
            agreeService: e,
            agreeInfo: e,
            agreeMarketing: e,
        });
    };

    const fnChangeAgree = (checkboxName) => {
        setAgree((prevAgree) => {
            const update = {
                ...prevAgree,
                [checkboxName]: !prevAgree[checkboxName],
            };

            const allChecked = Object.values(update).every(
                (isChecked) => isChecked,
            );
            setAllAgree(allChecked);

            return update;
        });
    };

    const fnPostAgreeTerms = async () => {
        const data = {
            user_idx: userIdx,
            terms_14age_fg: agree.agreeAge ? 0 : 1,
            terms_service_fg: agree.agreeService ? 0 : 1,
            terms_privacy_fg: agree.agreeInfo ? 0 : 1,
            terms_marketing: agree.agreeMarketing ? 0 : 1,
        };

        try {
            const res = await postMemberAgreeTermsAPI({ data });
            const { responseCode } = res?.data;

            if (responseCode === 200) onClick();
        } catch (err) {
            console.error(err);
            setErrorData(err);
        }
    };

    return (
        <BaseLayout header={false} footer={false}>
            <div className="popup-list terms-agree-modal">
                <div className="popup-wrap">
                    <div className="popup-title">
                        <strong>{PLEASEAGREETERMS}</strong>
                        <div className="reference">
                            <p className="reference-s">
                                {INFOANDSERVICE}
                                <br className="only-mobile" />
                                {GOODKEEP}
                            </p>
                        </div>
                    </div>
                    <div className="popup-content">
                        <div className="terms-chcek">
                            <div className="checkbox check-all">
                                <BaseInput
                                    id={'checkAll'}
                                    name={'checkAll'}
                                    type={'checkbox'}
                                    checked={allAgree}
                                    onChange={fnChangeAllAgree}
                                />
                                <label htmlFor="checkAll">
                                    <span className="check-mark"></span>
                                    {ALLAGREE}
                                </label>
                            </div>
                            <ul>
                                <li>
                                    <div className="checkbox">
                                        <BaseInput
                                            id={'checkAge'}
                                            name={'checkAge'}
                                            type={'checkbox'}
                                            checked={agree.agreeAge}
                                            onChange={() => {
                                                fnChangeAgree('agreeAge');
                                            }}
                                        />
                                        <label htmlFor="checkAge">
                                            <span className="check-mark"></span>
                                            [{REQUIRE}] {MOREAGE}
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className="checkbox">
                                        <BaseInput
                                            id={'checkService'}
                                            type={'checkbox'}
                                            checked={agree.agreeService}
                                            onChange={() => {
                                                fnChangeAgree('agreeService');
                                            }}
                                        />
                                        <label htmlFor="checkService">
                                            <span className="check-mark"></span>
                                            [{REQUIRE}] {AGREESERVICETERMS}
                                        </label>
                                        <BaseLink
                                            to={URL_MORE_SERVICE_DETAIL_USE}
                                            className="view-more"
                                        >
                                            {SEEALL}
                                        </BaseLink>
                                    </div>
                                </li>
                                <li>
                                    <div className="checkbox">
                                        <BaseInput
                                            id={'checkInfo'}
                                            type={'checkbox'}
                                            checked={agree.agreeInfo}
                                            onChange={() => {
                                                fnChangeAgree('agreeInfo');
                                            }}
                                        />
                                        <label htmlFor="checkInfo">
                                            <span className="check-mark"></span>
                                            [{REQUIRE}] {AGREEINFO}
                                        </label>
                                        <BaseLink
                                            to={
                                                URL_MORE_SERVICE_DETAIL_PERSONAL
                                            }
                                            className="view-more"
                                        >
                                            {SEEALL}
                                        </BaseLink>
                                    </div>
                                </li>
                                <li>
                                    <div className="checkbox">
                                        <BaseInput
                                            id={'checkMarketing'}
                                            type={'checkbox'}
                                            checked={agree.agreeMarketing}
                                            onChange={() => {
                                                fnChangeAgree('agreeMarketing');
                                            }}
                                        />
                                        <label htmlFor="checkMarketing">
                                            <span className="check-mark"></span>
                                            [{SELECT}] {AGREEMARKETING}
                                        </label>
                                        <BaseLink
                                            to={
                                                URL_MORE_SERVICE_DETAIL_MARKETING
                                            }
                                            className="view-more"
                                        >
                                            {SEEALL}
                                        </BaseLink>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="button-wrap-agreement">
                        <BaseButton
                            label={NEXT}
                            disabled={
                                ((!agree.agreeMarketing ||
                                    agree.agreeMarketing) &&
                                    agree.agreeAge &&
                                    agree.agreeInfo &&
                                    agree.agreeService) ||
                                allAgree
                                    ? false
                                    : true
                            }
                            onClick={fnPostAgreeTerms}
                        />
                    </div>
                </div>
            </div>

            {/* 에러 */}
            {ErrorModal}
        </BaseLayout>
    );
};
