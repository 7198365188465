import { useEffect, useState } from 'react';
import axios from 'axios';
import { getLocation, isMobile } from 'utils/mobile';
import ClipLoader from 'react-spinners/ClipLoader';
import { getAreaListAPI } from 'api/member';
import { parseAddr } from 'utils/utils';
import { useSelector } from 'react-redux';
import { AGENT } from 'constants/Mobile';

// onFalse: 위치 정보가 없는 경우 onFalse 실행됨
const MyAreaMap = ({ common, getArea, onFalse }) => {
    const { kakao } = window;
    const AGENT_TYPE = common.agentType;
    const [loading, setLoading] = useState(true);

    const location = useSelector((state) => state.common.get('location'));

    const getCurrentPosition = () => {
        let latitude = null; // latitude - x - 위도
        let longitude = null; // longitude - y - 경도

        if (common.mobile) {
            if (
                AGENT_TYPE === AGENT.ANDROID_APP ||
                AGENT_TYPE === AGENT.ANDROID_WEB
            ) {
                // 일단은 안드로이드만 설정 ios는 추후
                if (location?.latitude && location?.longitude) {
                    latitude = location?.latitude;
                    longitude = location?.longitude;

                    fnGetMyAreaApi(latitude, longitude);
                    fnDrawMap(latitude, longitude);
                } else {
                    onFalse();
                }
            } else if (
                AGENT_TYPE === AGENT.IOS_APP ||
                AGENT_TYPE === AGENT.IOS_WEB
            ) {
                // 일단은 안드로이드만 설정 ios는 추후
                if (location?.latitude && location?.longitude) {
                    latitude = location?.latitude;
                    longitude = location?.longitude;
                    fnGetMyAreaApi(latitude, longitude).then(() => {
                        fnDrawMap(latitude, longitude);
                    });
                } else {
                    onFalse();
                }
            }
        } else {
            // console.log('PC')
            getLocation(
                async (position) => {
                    // 성공 콜백
                    if (position) {
                        // console.log(position)
                        await fnGetMyAreaApi(
                            position.coords.latitude,
                            position.coords.longitude,
                        ).then(() => {
                            fnDrawMap(
                                position?.coords?.latitude,
                                position?.coords?.longitude,
                            );
                        });
                    } else {
                        onFalse();
                    }
                },
                (err) => {
                    // 에러 콜백
                    onFalse();
                },
            );
        }
    };

    const fnDrawMap = (latitude, longitude) => {
        let container = document.getElementById('map');

        if (!kakao.maps) return;

        let options = {
            center: new kakao.maps.LatLng(latitude, longitude),
            level: 2,
        };
        let map = new kakao.maps.Map(container, options);
        let markerPosition = new kakao.maps.LatLng(latitude, longitude);
        let marker = new kakao.maps.Marker({ position: markerPosition });
        marker.setMap(map);
    };

    const fnGetMyAreaApi = async (latitude, longitude) => {
        // console.log('fnGetMyAreaApi ::: ', latitude, longitude);
        try {
            const apiKey = process.env.REACT_APP_KAKAO_REST_API_KEY;
            // 카카오 api 호출할 때는 x: 경도, y: 위도 로 바꿔서 호출해야 함
            // 기존 :              latitude - x - 위도, longitude - y - 경도
            // 카카오 api 호출 시 :  longitude - x - 위도, latitude - y - 경도  로 인식하면 됨
            const apiURL = `${process.env.REACT_APP_KAKAO_API_URL}?x=${longitude}&y=${latitude}`;
            const response = await axios.get(apiURL, {
                headers: {
                    Authorization: `KakaoAK ${apiKey}`,
                },
            });
            if (response) setLoading(false);
            if (response?.data?.documents.length > 0) {
                const myAreaData = response?.data?.documents[0];
                // console.log('loc data res:::', myAreaData)
                if (myAreaData) {
                    // 제인아 myAreaData?.region_1depth_name 이런 데이터는 업는데.....
                    // 도로명 주소 우선으로. 도로명 주소는 없고 지번 주소만 있을 경우에는 '서울' -> '서울특별시'로 치환되어야 함
                    if (
                        myAreaData?.region_1depth_name &&
                        myAreaData?.region_2depth_name
                    ) {
                        fnGetAreaList({
                            sido: myAreaData?.region_1depth_name,
                            gugun: myAreaData?.region_2depth_name,
                        });
                    } else if (myAreaData?.road_address?.address_name) {
                        const parsedAddr = parseAddr(
                            myAreaData?.road_address?.address_name,
                        ); // 도로명 주소 파싱
                        // console.log(`parsedAddr ${parsedAddr}`);
                        fnGetAreaList({
                            sido: parsedAddr?.sido,
                            gugun: parsedAddr?.gugun,
                        });
                    } else if (
                        myAreaData?.address?.region_1depth_name &&
                        myAreaData?.address?.region_2depth_name
                    ) {
                        fnGetAreaList({
                            sido: myAreaData?.address?.region_1depth_name,
                            gugun: myAreaData?.address?.region_2depth_name,
                        });
                    }
                }
                // getArea({sido: myAreaData?.region_1depth_name, gugun: myAreaData?.region_2depth_name})
            }
        } catch (err) {
            console.error(err);
        }
    };

    const fnGetAreaList = async (value) => {
        try {
            const res = await getAreaListAPI();
            const { data_list } = res?.data;
            const areaList = data_list?.reduce((acc, current) => {
                const key = current.area_sido;

                if (!acc[key]) acc[key] = [];

                acc[key].push(current);
                return acc;
            }, {});

            if (!getArea) return;

            let code = null;
            let dbSidoName = value?.sido; // 카카오에서 '서울' 이렇게 오는 경우에는 '서울특별시로' 대체하기 위함

            for (const sido in areaList) {
                const gugun = areaList[sido].find(
                    (item) =>
                        item.area_gugun === value?.gugun &&
                        item.area_sido.indexOf(value?.sido) !== -1,
                );
                // console.log(`find gugun ${sido} ${gugun}`)
                if (gugun && sido.indexOf(value?.sido) !== -1) {
                    code = gugun.area_code;
                    dbSidoName = sido;
                }
            }

            getArea({
                sido: dbSidoName,
                gugun: value?.gugun,
                code: code,
            });
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getCurrentPosition();
    }, [location]);

    return (
        <div>
            <div id="map" style={{ width: '100%', height: '450px' }}></div>
            {loading && (
                <ClipLoader color="#000000" loading={loading} size={30} />
            )}
        </div>
    );
};

export default MyAreaMap;
