import { Popup } from 'antd-mobile';
import { PC_MAX_WIDTH, MOBILE_MAX_WIDTH } from 'constants';
import styled from 'styled-components';

const PopupWrapper = styled(Popup)`
    @media (max-width: ${MOBILE_MAX_WIDTH}) {
        .adm-popup-body {
            position: fixed;
            top: 48px;
            background-color: var(--adm-color-background);
            z-index: calc(var(--z-index) + 10);
        }
        .adm-mask {
            --z-index: var(--adm-mask-z-index, 1000);
            position: fixed;
            top: 48px;
            left: 0;
            z-index: var(--z-index);
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    @media (min-width: ${PC_MAX_WIDTH}) {
        /* pc 에서 ant design Popup 가운데 정렬 */
        .adm-popup-body {
            position: fixed;
            margin-top: 15px;
            background-color: var(--adm-color-background);
            z-index: calc(var(--z-index) + 10);
            transform: translate(-50%, -50%) !important;
            &:has(.month-switch-wrap) {
                margin-top: 0;
                transform: translate(-50%, 0) !important;
                top: 48px;
            }
        }
        .adm-popup-body-position-top {
            width: 100%;
            top: 50%;
            left: 50%;
            max-width: ${PC_MAX_WIDTH};
        }
        .adm-mask {
            position: fixed;
            top: 0;
            left: 0;
            // left: 50%;
            // transform: translateX(-50%);
            z-index: 1;
            display: block;
            // width: 72rem;
            width: 100%;
            height: 100%;
        }
    }
`;
/**
 * 상단(헤더 밑)에 위치하는 Modal
 * @returns
 */
export function TopPopUp({ show, children, onClick, onMaskClick }) {
    return (
        <PopupWrapper
            label=""
            visible={show}
            mask={true}
            // height={'331px'} // 40+264+27 331
            // bodyStyle={{ marginTop: 'var(--header-height)'}}
            // bodyStyle={{ marginTop: '48px'}}
            position="top"
            onClick={() => {
                if (onClick) onClick();
            }}
            onMaskClick={onMaskClick}
        >
            {children}
        </PopupWrapper>
    );
}
