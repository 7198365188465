import { Popup } from 'antd-mobile';
import { PC_MAX_WIDTH } from 'constants';
import { useEffect } from 'react';
import styled from 'styled-components';

const PopupWrapper = styled(Popup)`
    .adm-popup-body-position-center {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) !important;
    }
    @media (min-width: ${PC_MAX_WIDTH}) {
        /* pc 에서 ant design Popup 중앙 가운데 정렬 */
        .adm-popup-body {
            position: fixed;
            background-color: var(--adm-color-background);
            z-index: calc(var(--z-index) + 10);
            transform: translate(-50%, -50%) !important;
        }
        .adm-popup-body-position-center {
            width: 100%;
            top: 50%;
            left: 50%;
            max-width: ${PC_MAX_WIDTH};
        }
    }
`;

/**
 * 상단(헤더 밑)에 위치하는 Modal
 * width : px or %
 * @returns
 */
export function CenterPopUp({
    label = '',
    show,
    width,
    children,
    onMaskClick,
}) {
    let style = {
        // padding: '24px',
        // borderRadius: '2.4rem',
    };
    if (width) {
        style = { ...style, width };
    }

    return (
        <PopupWrapper
            label={label}
            visible={show}
            mask={true}
            bodyStyle={show ? { ...style } : { ...style, display: 'none' }}
            position="center"
            onMaskClick={onMaskClick}
        >
            {children}
        </PopupWrapper>
    );
}
