import { BaseTextButton } from 'components/elements';
import { BottomPopUp } from 'components/containers/BottomPopUp';
import { SELECTSUBJECT, CLOSE, CHECK } from 'constants';
import { useEffect, useState } from 'react';
import { BaseIconButton } from 'components/elements/BaseButton';
import { IconCloseSub } from 'components/assets/icon';
import { SELECTALL, CANMULTI } from 'constants';

export const SubjectMultiModal = ({
    show,
    dataList,
    onOk,
    onClose,
    defaultData = null,
}) => {
    // multi 글작성할때는 1개선택 / 목록에서는 여러개선택
    const [subjectList, setSubjectList] = useState([]);
    const [multiChoice, setMultiChoice] = useState([]);
    const [subChoice, setSubChoice] = useState([]);
    const [currentSelect, setCurrentSelect] = useState(null);
    const [isAll, setIsAll] = useState(null);

    // 전체 선택 버튼 클릭 시
    const fnSelectAll = () => {
        if (isAll) {
            setMultiChoice([]);
            setSubChoice([]);
            setCurrentSelect((prev) => ({ ...prev, disabled: true }));
            setIsAll(false);
        } else {
            const choiceArr = subjectList?.map((item) => item);
            const subArr = subjectList?.flatMap((item) =>
                item?.subList?.map((sub) => sub),
            );
            setMultiChoice(choiceArr);
            setSubChoice(subArr);
            setIsAll(true);
            setCurrentSelect((prev) => ({ ...prev, disabled: false }));
        }
    };

    // 확인 버튼 클릭 시
    const fnHandleOk = () => {
        if (onOk) {
            // const choiceArr = multiChoice?.map(item => item.idx);
            // const subChoiceArr = subChoice?.map(item => item.idx);
            // onOk([...new Set(choiceArr?.concat(subChoiceArr))]);
            onOk([...new Set(multiChoice)]);
        }
    };

    // 닫기 버튼 클릭 시
    const fnCloseSubjectModal = () => {
        if (!onClose) return;
        onClose();
        setMultiChoice(defaultData);
        // 선택하지 않고 닫을 경우 기존에 선택한 리스트와 주제 리스트 length 가 같지 않으면 전체선택 해제시킴
        setIsAll(defaultData?.length === subjectList?.length ? true : false);
        // 기존에 선택한 주제가 없으면 확인 버튼 비활성화
        setCurrentSelect({
            ...currentSelect,
            disabled: defaultData?.length === 0 ? true : false,
        });
    };

    // 상위 주제 선택 시
    const fnClickItem = (item) => {
        // console.log(item);
        // 이미 있으면 제거
        if (multiChoice.some((choice) => choice.idx === item.idx)) {
            setCurrentSelect({
                item,
                disabled: multiChoice?.length < 2 ? true : false,
            });
            setMultiChoice((prev) =>
                prev.filter((choice) => choice.idx !== item.idx),
            );
            if (item?.subList?.length > 0) setSubChoice([]);
            setIsAll(false);
            // 아니면 추가
        } else {
            // setCurrentSelect({item: item, disabled: item?.subList?.length > 0 ? true : false})
            // 리스트에 set 하기 전 length 비교해서 전체선택 체크할 건지 판단
            if (multiChoice?.length === subjectList?.length - 1) setIsAll(true);
            setCurrentSelect({ item, disabled: false });
            setMultiChoice((prev) => [...prev, item]);
        }
    };

    // 하위 주제 클릭 시
    /*
  const fnClickSubItem = (item) => {
    // 이미 있으면 제거
    if (subChoice?.some(sub => sub.idx === item.idx)) {
      setSubChoice(prev => {
        const newSubChoice = prev.filter(sub => sub.idx !== item.idx);    // 선택한 하위 주제가 있는지 ?
        multiChoice?.forEach(i => {     // 선택한 상위 주제를 순회하면서 하위 주제의 부모 주제가 있으면 선택한 하위 주제가 있는지 확인
          if (i.idx === item?.parent_idx) {
            if (newSubChoice?.length === 0) {
              setCurrentSelect({...currentSelect, disabled: true});       // 선택한 하위 주제가 없으면 확인 버튼 비활성화 
            }
          }
        });
        return newSubChoice;
      });
      setIsAll(false);
      // 아니면 추가
    } else {
      setSubChoice(prev => [...prev, item]);
      multiChoice?.forEach(i => {
        if (i.idx === item.parent_idx) setCurrentSelect({...currentSelect, disabled: false})
      })
      // 하위 주제의 부모 주제를 강제로 선택하게 만들음
      subjectList?.forEach(i => {
        if (i.idx === item?.parent_idx) setMultiChoice(prev => [...prev, i]);
      })
    }
  }
  */

    // 확인완료 제외하기 위한 코드
    useEffect(() => {
        // console.log(defaultData)
        if (dataList) {
            /*
      let filterArr = [];
      dataList?.forEach(item => {
        if (defaultData && defaultData === item.idx) {
        } 
        if (item.subList.length > 0) {
          item.subList.forEach(sub => {
            if(sub.idx === COMMUNITY_CHANGE_CHECK_IDX) {
              filterArr.push(sub.idx);
            }
          })
        }
      })
      const filterList = dataList?.filter(item => {
        if (item?.subList?.length > 0) {
          // item.subList = item.subList.filter(sub => !filterArr.includes(sub.idx));
        }
        return item;
      });
      setSubjectList(filterList);
      */
            setSubjectList(dataList);
        }
        if (defaultData) {
            setMultiChoice(defaultData);
            // 모든 주제를 선택한 경우 전체선택 선택되어 있게 설정
            setIsAll(defaultData?.length === dataList?.length ? true : false);
            // 기존에 선택한 주제가 없으면 확인 버튼 비활성화
            setCurrentSelect((prev) => ({
                ...prev,
                disabled: defaultData?.length > 0 ? false : true,
            }));
        }
    }, [dataList, defaultData]);

    const ListItem = ({ item, className, onClick }) => {
        return (
            <li>
                {/* {className} */}
                <BaseTextButton
                    className={className}
                    onClick={onClick}
                    label={item}
                />
            </li>
        );
    };

    return (
        <BottomPopUp show={show} onMaskClick={fnCloseSubjectModal}>
            <div className="popup-bottom">
                <div className="popup-wrap subject-modal">
                    <div className="popup-head popup-close">
                        <strong>{SELECTSUBJECT}</strong>
                        <BaseIconButton onClick={fnCloseSubjectModal}>
                            <IconCloseSub altText={CLOSE} />
                        </BaseIconButton>
                        <p className="popup-title-sub">{CANMULTI}</p>
                    </div>
                    <div className="popup-content">
                        <ul className="category">
                            <ListItem
                                item={SELECTALL}
                                className={isAll ? 'check' : ''}
                                onClick={fnSelectAll}
                            />
                            {subjectList?.map((item, idx) => (
                                <ListItem
                                    key={`category_${idx}`}
                                    item={item?.category_name}
                                    className={
                                        isAll
                                            ? 'check'
                                            : multiChoice
                                                    ?.map((i) => i.idx)
                                                    ?.includes(item.idx)
                                              ? 'check'
                                              : ''
                                    }
                                    onClick={() => fnClickItem(item)}
                                />
                            ))}
                            {/*
                  // 선택한 상위 주제에 하위 주제가 있을 때
                  currentSelect?.item?.subList?.length > 0 &&
                  <>
                    <li className="help">
                      <BaseLink>{HELPWHAT}</BaseLink>
                    </li>
                    {
                      currentSelect?.item?.subList?.map((item, idx) => (
                        <ListItem
                          key={`categorySub_${idx}`}
                          item={item?.category_name}
                          className={subChoice?.map(i => i.idx)?.includes(item.idx) ? 'check' : ''}
                          onClick={() => fnClickSubItem(item)}
                        />
                      ))
                    }
                  </>
                  */}
                        </ul>
                    </div>
                    <div className="popup-button-wrap">
                        <BaseTextButton
                            label={CHECK}
                            className={
                                !currentSelect?.disabled ? 'active' : 'disabled'
                            }
                            disabled={currentSelect?.disabled}
                            onClick={fnHandleOk}
                        />
                    </div>
                </div>
            </div>
        </BottomPopUp>
    );
};
