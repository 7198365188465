import { IconSearchOff } from 'components/assets/icon';
import { SEARCH, CHECKKEYWORD, NOSEARCHRESULT } from 'constants';

export const NoSearchResult = () => {
    return (
        <>
            {/* <div className="group__imgText">
        <IconSearchOff altText={SEARCH} />
        <p>{NOSEARCHRESULT}</p>
        <p>{CHECKKEYWORD}</p>
      </div> */}
            <section className="empty__content align center vm">
                <div className="group__imgText">
                    <IconSearchOff altText={SEARCH} />
                    <p>{NOSEARCHRESULT}</p>
                    <p>{CHECKKEYWORD}</p>
                </div>
            </section>
        </>
    );
};
