/**
 * @author kkazzong
 * @desc 헤더
 * pathname에 따라 헤더 결정
 */

import { useLocation } from 'react-router-dom';
import {
    URL_HOME,
    URL_COMMUNITY,
    URL_DIARY,
    URL_WALLET,
} from 'routes/RouteUrl';
import { BaseHeaderHome } from './BaseHeaderHome';
import { BaseHeaderCommunity } from './BaseHeaderCommunity';
import { BaseHeaderNote } from './BaseHeaderNote';
import { BaseHeaderAction } from './BaseHeaderAction';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export function BaseHeader() {
    const location = useLocation();
    const push = useSelector((state) => state.push.get('push'));
    const { pathname } = location;

    const [hasAlram, setHasAlram] = useState(false);

    useEffect(() => {
        if (push) setHasAlram(true);
        // const { boardList, communityList, walletList } = push;
        // if (boardList.length > 0 || communityList.length > 0 || walletList.length > 0) setHasAlram(true)
    }, [push]);

    const Header = () => {
        switch (pathname) {
            case URL_HOME:
                return <BaseHeaderHome />;
            case URL_COMMUNITY:
                return <BaseHeaderCommunity />;
            case URL_DIARY:
            case URL_WALLET:
                return <BaseHeaderNote menuChoice={pathname} />;
            default:
                return <BaseHeaderHome />;
        }
    };

    return (
        <header
            className={
                pathname === URL_HOME ? 'layout-header2' : 'layout-header'
            }
        >
            <Header />
            <BaseHeaderAction hasAlram={hasAlram} pathname={pathname} />
        </header>
    );
}
