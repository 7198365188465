export function ToastModal({ show, message }) {
    // return (
    //   <div className='toast-message-common'></div>
    //   <div className="popup-toast">
    //     {/* 토스트메시지 */}
    //     {children}
    //   </div>
    // )

    //'추천 코드가 복사되었습니다. \a 다른 곳에 복사 붙여넣기가 가능합니다.'

    return (
        show && (
            <>
                <div
                    className="toast-message-common"
                    data-content={message}
                ></div>
                {/* 토스트메시지 */}
                {/* <div className="popup-toast">
        {children}
      </div> */}
            </>
        )
    );
}
