export const TYPES = {
    SET_MY_HISTORY_LIST: 'SET_MY_HISTORY_LIST',
    SET_MY_LIKE_LIST: 'SET_MY_LIKE_LIST',
    SET_MY_NEIGHBORS_LIST: 'SET_MY_NEIGHBORS_LIST',
    SET_MY_BLOCKED_NEIGHBORS_LIST: 'SET_MY_BLOCKED_NEIGHBORS_LIST',
};

const initState = {
    historyList: [],
    likeList: [],
    neighborsList: [],
    blockedNeighborsList: [],
};

export default function HistoryReducer(state = initState, action) {
    switch (action.type) {
        case TYPES.SET_MY_HISTORY_LIST:
            return {
                ...state,
                historyList: action.payload,
            };

        case TYPES.SET_MY_LIKE_LIST:
            return {
                ...state,
                likeList: action.payload,
            };

        case TYPES.SET_MY_NEIGHBORS_LIST:
            return {
                ...state,
                neighborsList: action.payload,
            };

        case TYPES.SET_MY_BLOCKED_NEIGHBORS_LIST:
            return {
                ...state,
                blockedNeighborsList: action.payload,
            };

        default:
            return state;
    }
}

export const actions = {
    setMyHistoryList: (history) => ({
        type: TYPES.SET_MY_HISTORY_LIST,
        payload: history,
    }),

    setMyLikeList: (like) => ({ type: TYPES.SET_MY_LIKE_LIST, payload: like }),

    setMyNeighborsList: (neighbors) => ({
        type: TYPES.SET_MY_NEIGHBORS_LIST,
        payload: neighbors,
    }),

    setMyBlockedNeighborsList: (neighbors) => ({
        type: TYPES.SET_MY_BLOCKED_NEIGHBORS_LIST,
        payload: neighbors,
    }),
};
