import { CenterPopup } from 'antd-mobile';
import styled from 'styled-components';

// const CenterPopup = styled(Popup)`
// .adm-center-popup-body:has(.confirm-modal) {
//   background-color: none !important;
// }
// `
export function ConfirmModal({
    show,
    label = '로그아웃 하시겠어요?',
    subtitle = '언제든 다시 돌아오실 수 있어요.',
    footer,
    children,
    onOk,
    onClose,
}) {
    const fnHandleMaskClick = () => {
        if (onClose) onClose();
    };

    return (
        <CenterPopup visible={show} onMaskClick={fnHandleMaskClick}>
            <div className="popup-list confirm-modal">
                <div className="popup-wrap-center">
                    <div className="popup-head">
                        <strong className="popup-title1">{label}</strong>
                        <div className="reference">
                            <p className="reference-s2">{subtitle}</p>
                        </div>
                    </div>
                    {children && (
                        <div className="popup-content">{children}</div>
                    )}

                    <div className="popup-center-footer button-wrap2">
                        {footer}
                    </div>
                </div>
            </div>
        </CenterPopup>
    );
}
