// 공휴일 (2022 - 2034)

/*
kind: 1 : 국경일 2 : 공휴일

*/
export const HOLIDAY = [
    {
        kind: 1,
        holidate: '20220101',
        holiname: '새해',
    },
    {
        kind: 1,
        holidate: '20220131',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20220201',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20220202',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20220301',
        holiname: '삼일절',
    },
    {
        kind: 1,
        holidate: '20220309',
        holiname: '20대 대통령 선거일',
    },
    {
        kind: 1,
        holidate: '20220505',
        holiname: '어린이날',
    },
    {
        kind: 1,
        holidate: '20220508',
        holiname: '부처님오신날',
    },
    {
        kind: 1,
        holidate: '20220606',
        holiname: '현충일',
    },
    {
        kind: 1,
        holidate: '20220815',
        holiname: '광복절',
    },
    {
        kind: 1,
        holidate: '20220909',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20220910',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20220911',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20220912',
        holiname: '추석(대체 휴일)',
    },
    {
        kind: 1,
        holidate: '20221003',
        holiname: '개천절',
    },
    {
        kind: 1,
        holidate: '20221009',
        holiname: '한글날',
    },
    {
        kind: 1,
        holidate: '20221010',
        holiname: '한글날(대체 휴일)',
    },
    {
        kind: 1,
        holidate: '20221225',
        holiname: '크리스마스',
    },
    {
        kind: 1,
        holidate: '20230101',
        holiname: '새해',
    },
    {
        kind: 1,
        holidate: '20230121',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20230122',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20230123',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20230124',
        holiname: '설날(대체 휴일)',
    },
    {
        kind: 1,
        holidate: '20230301',
        holiname: '삼일절',
    },
    {
        kind: 1,
        holidate: '20230505',
        holiname: '어린이날',
    },
    {
        kind: 1,
        holidate: '20230527',
        holiname: '부처님오신날',
    },
    {
        kind: 1,
        holidate: '20230529',
        holiname: '부처님오신날(대체 휴일)',
    },
    {
        kind: 1,
        holidate: '20230606',
        holiname: '현충일',
    },
    {
        kind: 1,
        holidate: '20230815',
        holiname: '광복절',
    },
    {
        kind: 1,
        holidate: '20230928',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20230929',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20230930',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20231002',
        holiname: '임시공휴일',
    },
    {
        kind: 1,
        holidate: '20231003',
        holiname: '개천절',
    },
    {
        kind: 1,
        holidate: '20231009',
        holiname: '한글날',
    },
    {
        kind: 1,
        holidate: '20231225',
        holiname: '크리스마스',
    },
    {
        kind: 1,
        holidate: '20240101',
        holiname: '새해',
    },
    {
        kind: 1,
        holidate: '20240209',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20240210',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20240211',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20240212',
        holiname: '설날(대체 휴일)',
    },
    {
        kind: 1,
        holidate: '20240301',
        holiname: '삼일절',
    },
    {
        kind: 1,
        holidate: '20240410',
        holiname: '22대 국회의원 선거일',
    },
    {
        kind: 1,
        holidate: '20240505',
        holiname: '어린이날',
    },
    {
        kind: 1,
        holidate: '20240506',
        holiname: '어린이날(대체 휴일)',
    },
    {
        kind: 1,
        holidate: '20240515',
        holiname: '부처님오신날',
    },
    {
        kind: 1,
        holidate: '20240606',
        holiname: '현충일',
    },
    {
        kind: 1,
        holidate: '20240815',
        holiname: '광복절',
    },
    {
        kind: 1,
        holidate: '20240916',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20240917',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20240918',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20241003',
        holiname: '개천절',
    },
    {
        kind: 1,
        holidate: '20241009',
        holiname: '한글날',
    },
    {
        kind: 1,
        holidate: '20241225',
        holiname: '크리스마스',
    },
    {
        kind: 1,
        holidate: '20250101',
        holiname: '새해',
    },
    {
        kind: 1,
        holidate: '20250128',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20250129',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20250130',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20250301',
        holiname: '삼일절',
    },
    {
        kind: 1,
        holidate: '20250505',
        holiname: '어린이날/부처님오신날',
    },
    {
        kind: 1,
        holidate: '20250506',
        holiname: '어린이날(대체 휴일)',
    },
    {
        kind: 1,
        holidate: '20250606',
        holiname: '현충일',
    },
    {
        kind: 1,
        holidate: '20250815',
        holiname: '광복절',
    },
    {
        kind: 1,
        holidate: '20251003',
        holiname: '개천절',
    },
    {
        kind: 1,
        holidate: '20251005',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20251006',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20251007',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20251008',
        holiname: '추석(대체 휴일)',
    },
    {
        kind: 1,
        holidate: '20251009',
        holiname: '한글날',
    },
    {
        kind: 1,
        holidate: '20251225',
        holiname: '크리스마스',
    },
    {
        kind: 1,
        holidate: '20260101',
        holiname: '새해',
    },
    {
        kind: 1,
        holidate: '20260216',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20260217',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20260218',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20260301',
        holiname: '삼일절',
    },
    {
        kind: 1,
        holidate: '20260302',
        holiname: '삼일절(대체 휴일)',
    },
    {
        kind: 1,
        holidate: '20260505',
        holiname: '어린이날',
    },
    {
        kind: 1,
        holidate: '20260524',
        holiname: '부천님오신날',
    },
    {
        kind: 1,
        holidate: '20260525',
        holiname: '부천님오신날(대체 휴일)',
    },
    {
        kind: 1,
        holidate: '20260606',
        holiname: '현충일',
    },
    {
        kind: 1,
        holidate: '20260815',
        holiname: '광복절',
    },
    {
        kind: 1,
        holidate: '20260817',
        holiname: '광복절(대체 휴일)',
    },
    {
        kind: 1,
        holidate: '20260924',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20260925',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20260926',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20261003',
        holiname: '개천절',
    },
    {
        kind: 1,
        holidate: '20261005',
        holiname: '개천절(대체 휴일)',
    },
    {
        kind: 1,
        holidate: '20261009',
        holiname: '한글날',
    },
    {
        kind: 1,
        holidate: '20261225',
        holiname: '크리스마스',
    },
    {
        kind: 1,
        holidate: '20270101',
        holiname: '새해',
    },
    {
        kind: 1,
        holidate: '20270206',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20270207',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20270208',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20270209',
        holiname: '설날(대체 휴일)',
    },
    {
        kind: 1,
        holidate: '20270301',
        holiname: '삼일절',
    },
    {
        kind: 1,
        holidate: '20270505',
        holiname: '어린이날',
    },
    {
        kind: 1,
        holidate: '20270513',
        holiname: '부천님오신날',
    },
    {
        kind: 1,
        holidate: '20270606',
        holiname: '현충일',
    },
    {
        kind: 1,
        holidate: '20270815',
        holiname: '광복절',
    },
    {
        kind: 1,
        holidate: '20270816',
        holiname: '광복절(대체 휴일)',
    },
    {
        kind: 1,
        holidate: '20270914',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20270915',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20270916',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20271003',
        holiname: '개천절',
    },
    {
        kind: 1,
        holidate: '20271004',
        holiname: '개천절(대체 휴일)',
    },
    {
        kind: 1,
        holidate: '20271009',
        holiname: '한글날',
    },
    {
        kind: 1,
        holidate: '20271010',
        holiname: '한글날(대체 휴일)',
    },
    {
        kind: 1,
        holidate: '20271225',
        holiname: '크리스마스',
    },
    {
        kind: 1,
        holidate: '20280101',
        holiname: '새해',
    },
    {
        kind: 1,
        holidate: '20280126',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20280127',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20280128',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20280301',
        holiname: '삼일절',
    },
    {
        kind: 1,
        holidate: '20280502',
        holiname: '부천님오신날',
    },
    {
        kind: 1,
        holidate: '20280505',
        holiname: '어린이날',
    },
    {
        kind: 1,
        holidate: '20280606',
        holiname: '현충일',
    },
    {
        kind: 1,
        holidate: '20280815',
        holiname: '광복절',
    },
    {
        kind: 1,
        holidate: '20281002',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20281003',
        holiname: '추석/개천절',
    },
    {
        kind: 1,
        holidate: '20281005',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20281009',
        holiname: '한글날',
    },
    {
        kind: 1,
        holidate: '20281225',
        holiname: '크리스마스',
    },
    {
        kind: 1,
        holidate: '20290101',
        holiname: '새해',
    },
    {
        kind: 1,
        holidate: '20290212',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20290213',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20290214',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20290301',
        holiname: '삼일절',
    },
    {
        kind: 1,
        holidate: '20290505',
        holiname: '어린이날',
    },
    {
        kind: 1,
        holidate: '20290520',
        holiname: '부천님오신날',
    },
    {
        kind: 1,
        holidate: '20290606',
        holiname: '현충일',
    },
    {
        kind: 1,
        holidate: '20290815',
        holiname: '광복절',
    },
    {
        kind: 1,
        holidate: '20290921',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20290922',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20290923',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20291003',
        holiname: '개천절',
    },
    {
        kind: 1,
        holidate: '20291009',
        holiname: '한글날',
    },
    {
        kind: 1,
        holidate: '20291225',
        holiname: '크리스마스',
    },
    {
        kind: 1,
        holidate: '20300101',
        holiname: '새해',
    },
    {
        kind: 1,
        holidate: '20300202',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20300203',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20300204',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20300301',
        holiname: '삼일절',
    },
    {
        kind: 1,
        holidate: '20300505',
        holiname: '어린이날',
    },
    {
        kind: 1,
        holidate: '20300509',
        holiname: '부천님오신날',
    },
    {
        kind: 1,
        holidate: '20300606',
        holiname: '현충일',
    },
    {
        kind: 1,
        holidate: '20300815',
        holiname: '광복절',
    },
    {
        kind: 1,
        holidate: '20300911',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20300912',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20300913',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20301003',
        holiname: '개천절',
    },
    {
        kind: 1,
        holidate: '20301009',
        holiname: '한글날',
    },
    {
        kind: 1,
        holidate: '20301225',
        holiname: '크리스마스',
    },
    {
        kind: 1,
        holidate: '20310101',
        holiname: '새해',
    },
    {
        kind: 1,
        holidate: '20310222',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20310223',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20310224',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20310301',
        holiname: '삼일절',
    },
    {
        kind: 1,
        holidate: '20310505',
        holiname: '어린이날',
    },
    {
        kind: 1,
        holidate: '20310528',
        holiname: '부천님오신날',
    },
    {
        kind: 1,
        holidate: '20310606',
        holiname: '현충일',
    },
    {
        kind: 1,
        holidate: '20310815',
        holiname: '광복절',
    },
    {
        kind: 1,
        holidate: '20310930',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20311001',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20311002',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20311003',
        holiname: '개천절',
    },
    {
        kind: 1,
        holidate: '20311009',
        holiname: '한글날',
    },
    {
        kind: 1,
        holidate: '20311225',
        holiname: '크리스마스',
    },
    {
        kind: 1,
        holidate: '20320101',
        holiname: '새해',
    },
    {
        kind: 1,
        holidate: '20320210',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20320211',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20320212',
        holiname: '설날',
    },
    {
        kind: 1,
        holidate: '20320301',
        holiname: '삼일절',
    },
    {
        kind: 1,
        holidate: '20320505',
        holiname: '어린이날',
    },
    {
        kind: 1,
        holidate: '20320516',
        holiname: '부천님오신날',
    },
    {
        kind: 1,
        holidate: '20320606',
        holiname: '현충일',
    },
    {
        kind: 1,
        holidate: '20320815',
        holiname: '광복절',
    },
    {
        kind: 1,
        holidate: '20320918',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20320919',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20320920',
        holiname: '추석',
    },
    {
        kind: 1,
        holidate: '20321003',
        holiname: '개천절',
    },
    {
        kind: 1,
        holidate: '20321009',
        holiname: '한글날',
    },
    {
        kind: 1,
        holidate: '20321225',
        holiname: '크리스마스',
    },
];

export const getHoiliday = (dateArray) => {
    const holidays = HOLIDAY.filter((item) =>
        dateArray.includes(item.holidate),
    ).map((item) => item.holidate);
    // console.log(holidays);
    return holidays;
};
