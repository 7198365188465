import { Map } from 'immutable';

export const TYPES = {
    POST_PUSH: 'POST_PUSH',
};

const initState = Map({
    // push: {
    //   boardList: [],
    //   communityList: [],
    //   walletList: []
    // },
    push: null,
});

export default function PushReducer(state = initState, action) {
    switch (action.type) {
        case TYPES.POST_PUSH:
            return state.set('push', action.push);

        default:
            return state;
    }
}

export const actions = {
    postPush: ({ push }) => ({ type: TYPES.POST_PUSH, push }),
};
