import { combineReducers } from 'redux';

import AuthReducer from 'store/auth-reducer';
import PushReducer from 'store/push-reducer';
import CommonReducer from 'store/common-reducer';
import MainReducer from './main-reducer';
import ListReducer from './list-reducer';
import AlarmReducer from './alarm-reducer';
import HistoryReducer from './history-reducer';

const rootReducers = combineReducers({
    push: PushReducer,
    auth: AuthReducer,
    common: CommonReducer,
    main: MainReducer,
    list: ListReducer,
    alarm: AlarmReducer,
    history: HistoryReducer,
});

export default rootReducers;
