import { Popup } from 'antd-mobile';
import { BottomPopUp } from 'components/containers';
import { BaseButton } from 'components/elements';
import { useEffect, useState } from 'react';

export const ChoiceModal = ({
    show,
    hasBottom = false,
    dataList,
    onClick,
    onOk,
    onClose,
}) => {
    const [choice, setChoice] = useState();

    // 24.08.01 mcb 요청에 의한 모달내 확인버튼 일괄 삭제
    // const fnClickItem = (value) => {
    //   setChoice(value);
    //   if (onClick) onClick(value);
    // }

    // 24.08.01 mcb 요청에 의한 모달내 확인버튼 일괄 삭제
    // const fnHandleOk = () => {
    //   if (onOk) onOk(choice);
    // }

    const fnHandleMaskClick = () => {
        if (onClose) onClose();
    };

    useEffect(() => {
        setChoice();
    }, [show]);

    return (
        // <Popup
        //   visible={show}
        //   mask={mask}
        //   position={position}
        //   bodyStyle={{
        //     ...bodyStyle,
        //     minHeight: '27rem',
        //   }}
        // >

        //btnClassName : toast-message를 띄우기 위한 클래스
        <BottomPopUp
            show={show}
            hasBottom={hasBottom}
            onMaskClick={fnHandleMaskClick}
        >
            <div className="popup-bottom">
                <div className="popup-wrap choice-modal">
                    <div className="popup-content">
                        <ul className="category">
                            {dataList?.map((item, idx) => (
                                <li
                                    className={
                                        choice === item.value ? 'checked' : ''
                                    }
                                    key={`choice-idx-${idx}`}
                                >
                                    <button
                                        className={`${item.btnClassName} ${item.color ? `point-${item.color}` : ''}`}
                                        onClick={() => {
                                            //fnClickItem(item.value)
                                            onOk(item.value);
                                        }}
                                        type="button"
                                    >
                                        {item.label}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {/* 24.08.01 mcb 요청에 의한 모달내 확인버튼 일괄 삭제 */}
                    {/* <div className="popup-button-wrap">
            <BaseButton label={"확인"} onClick={fnHandleOk}/>
          </div> */}
                </div>
            </div>
        </BottomPopUp>
        // </Popup>
    );
};
