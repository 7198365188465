import { postFileApi } from 'api/common';
import imageCompression from 'browser-image-compression';
import { IMG_MAX_WIDTH, IMG_MAX_SIZE } from 'constants';

export const upload = async (file, params, callback) => {
    const form = new FormData();
    // const files = e.target.files[0]
    form.append('upload', file);

    try {
        const res = await postFileApi({ ...params, data: form });
        if (res) callback(res);
    } catch (err) {
        console.error(err);
    }
};

// 이미지 압축
export const compressImg = async (files) => {
    const compressed = await Promise.all(
        Array.from(files).map(async (file) => {
            const compression = await imageCompression(file, {
                maxSizeMB: IMG_MAX_SIZE, // 이미지 최대 사이즈 지정
                maxWidthOrHeight: IMG_MAX_WIDTH, // 이미지 크기 지정
                useWebWorker: true, // 멀티쓰레드 처리 true
            });
            // imageCompression 으로 압축하면 Blob 객체로 주기 때문에 => FileList 객체로 변환 필요
            const changeFileType = new File([compression], file.name, {
                type: compression.type,
                lastModified: Date.now(),
            });
            return changeFileType;
        }),
    );
    return compressed;
};

// base64string -> File로 변환
export const base64ToFile = async (base64string, type, fileName) => {
    const byteChars = atob(base64string); // atob 함수 사용해 바이너리 데이터로 변환
    const byteNumbers = new Array(byteChars.length);
    for (let i = 0; i < byteChars.length; i++) {
        // 바이너리 데이터를 숫자 배열로 변환
        byteNumbers[i] = byteChars.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers); // 숫자 배열을 Uint8Array로 변환
    const blob = new Blob([byteArray], { type: type }); // Blob 객체 생성
    const newFile = new File([blob], fileName, { type: type }); // Blob -> File 로 변환
    return newFile;
};
