import { IconSearchClose } from 'components/assets/icon';
import { BaseIconButton } from 'components/elements/BaseButton';
import { CLOSE, NORESULT } from 'constants';

export function RecentSearchArea({
    recentSearchKeyword,
    onClick,
    setSearchKeyword,
    setIsClickSearch,
}) {
    const onClickToSearch = (item) => {
        setSearchKeyword(item);
        setIsClickSearch(true);
    };

    return (
        <>
            {recentSearchKeyword.length > 0 ? (
                <ul className="recent-search__list">
                    {recentSearchKeyword.map((item, idx) => (
                        <li key={`search-idx-${idx}`} className="align both vm">
                            <p
                                onClick={() => onClickToSearch(item)}
                                style={{ cursor: 'pointer' }}
                            >
                                {item}
                            </p>
                            <BaseIconButton onClick={() => onClick(item)}>
                                <IconSearchClose altText={CLOSE} />
                            </BaseIconButton>
                        </li>
                    ))}
                </ul>
            ) : (
                <div className="recent-search-empty__content align center">
                    <p className="empty-search-log">{NORESULT}</p>
                </div>
            )}
        </>
    );
}
