/**
 * @author kkazzong
 * @desc 메인화면 헤더
 */
import { IconLogo } from 'components/assets/icon';
import { HOME } from 'constants/index';

export function BaseHeaderHome({ hasAlram = true }) {
    return (
        <div className={'layout-header-logo'}>
            <IconLogo />
            <div className={'logo-text'}>{HOME}</div>
        </div>
    );
}
