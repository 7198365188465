import { forwardRef } from 'react';
export const BaseTextarea = forwardRef(
    (
        {
            onBlur = undefined,
            onFocus = undefined,
            className = '',
            name = '',
            id = '',
            placeholder = '',
            value = '',
            onChange,
            onClick = undefined,
            maxLength = null,
        },
        ref,
    ) => {
        const handleInput = (e) => {
            if (onChange) onChange(e.target.value);
        };

        return (
            <textarea
                ref={ref ? ref : null}
                name={name}
                id={id}
                placeholder={placeholder}
                onChange={handleInput}
                value={value}
                onBlur={onBlur}
                onFocus={onFocus}
                maxLength={maxLength}
            ></textarea>
        );
    },
);
