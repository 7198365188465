import styled from 'styled-components';
import { motion } from 'framer-motion';
import useBottomSheet from './useBottomSheet';
import BottomSheetHeader from './BottomSheetHeader';
/**
 * ref) Wayne Kim
 * https://blog.mathpresso.com/bottom-sheet-for-web-55ed6cc78c00
 */

export const MIN_Y = 60; // BottomSheet 최대 Y
export const MAX_Y = window.innerHeight - 160; // BottomSheet 최소 Y
export const BOTTOM_SHEET_HEIGHT = window.innerHeight - MIN_Y; // BottomSheet height
// const top = window.innerHeight - 40;
// console.log('top', top);
const BottomSheetWrapper = styled(motion.div)`
    display: flex;
    flex-direction: column;

    position: fixed;
    z-index: 1;
    top: calc(100% - 10rem); /* BottomSheet 위치 */
    left: 0;
    right: 0;

    height: ${BOTTOM_SHEET_HEIGHT}px;
    border: 0.1rem solid #bdc3c7;
    border-radius: 1.2rem 1.2rem 0 0;
    background: #ffffff;

    max-width: 72rem;
    margin: auto;
`;

const ContentWrapper = styled.div`
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    /* background: red; */
`;

export function BottomSheet({ children }) {
    const { sheet, content } = useBottomSheet();

    return (
        <BottomSheetWrapper
            ref={sheet}
            initial="initial"
            animate="target"
            transition={{
                ease: 'easeInOut',
                duration: 0.7,
            }} /** animation speed */
        >
            <BottomSheetHeader />
            <ContentWrapper ref={content}>{children}</ContentWrapper>
        </BottomSheetWrapper>
    );
}
