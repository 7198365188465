import client from '../axios';
import {
    GET_FAQ,
    GET_FAQ_LIST,
    GET_GOODS_LIST,
    GET_IMAGE_LOG,
    GET_NOTE_SEARCH,
    GET_POPUP_LIST,
    GET_PUSH_LIST,
    GET_USER_INFO,
    POST_FILE,
    POST_PURCHASE_TOKEN,
    POST_TOKEN,
} from './endpoint';

export const postFileApi = ({ actionType, userIdx, contentIdx, data }) => {
    return client.post(
        POST_FILE.replace(':action_type', actionType)
            .replace(':user_idx', userIdx)
            .replace(':content_idx', contentIdx),
        data,
        { headers: { 'Content-Type': 'multipart/form-data' } },
    );
};
export const postToken = ({ data }) => {
    return client.post(POST_TOKEN, data);
};
export const getUserInfoApi = ({ userIdx }) => {
    return client.get(GET_USER_INFO.replace(':user_idx', userIdx));
};
export const getGoodsListApi = () => {
    return client.get(GET_GOODS_LIST);
};
export const getFaqListApi = ({ params }) => {
    return client.get(GET_FAQ_LIST, { params });
};
export const getFaqApi = ({ faqIdx }) => {
    return client.get(GET_FAQ.replace(':faq_idx', faqIdx));
};
export const getNoteSearchApi = ({ params }) => {
    return client.get(GET_NOTE_SEARCH, { params });
};
export const getPushListApi = ({ userIdx, params }) => {
    return client.get(GET_PUSH_LIST.replace(':user_idx', userIdx), { params });
};
export const getPopupListApi = ({ params }) => {
    return client.get(GET_POPUP_LIST, { params });
};
export const postPurchaseTokenApi = ({ data }) => {
    return client.post(POST_PURCHASE_TOKEN, data);
};
export const getImageLogApi = ({ params }) => {
    return client.get(GET_IMAGE_LOG, { params });
};
