import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
`;

const ToggleWrapper = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 11rem;
    height: 3.6rem;
    border: none;
    border-radius: 3.2rem;
    padding: 2px;
    background-color: #f5f5f5;
`;

const LeftToggle = styled.div`
    flex: 1;
    width: 5.6rem;
    border-radius: 3.2rem;
    text-align: center;
    font-style: normal;
    font-weight: ${(props) => (props.active === 'true' ? 700 : 500)};
    font-size: 1.4rem;
    line-height: ${(props) => (props.active === 'true' ? 2.2 : 1.6)};
    z-index: 1000;
    color: ${(props) => (props.active === 'true' ? '#000000' : '#BDBDBD')};
    background-color: ${(props) =>
        props.active === 'true' ? '#ffffff' : '#F5F5F5'};
`;

const RightToggle = styled.div`
    flex: 1;
    width: 5.6rem;
    text-align: center;
    border-radius: 3.2rem;
    font-style: normal;
    font-weight: ${(props) => (props.active === 'true' ? 500 : 700)};
    font-size: 1.4rem;
    line-height: ${(props) => (props.active === 'true' ? 1.6 : 2.2)};
    z-index: 1000;
    color: ${(props) => (props.active === 'true' ? '#BDBDBD' : '#000000')};
    background-color: ${(props) =>
        props.active === 'true' ? '#F5F5F5' : '#ffffff'};
`;

export function ToggleButton({ label = [], isLeftActive, onChange }) {
    const handleClick = () => {
        onChange(!isLeftActive);
    };

    return (
        <Wrapper>
            {/* isLeftActive.toString() - 콘솔 에러 조치 */}
            <ToggleWrapper
                active={isLeftActive.toString()}
                onClick={handleClick}
            >
                <LeftToggle active={isLeftActive.toString()}>
                    {label?.[0]}
                </LeftToggle>
                <RightToggle active={isLeftActive.toString()}>
                    {label?.[1]}
                </RightToggle>
            </ToggleWrapper>
        </Wrapper>
    );
}
