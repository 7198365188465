/**
 * @author kkazzong
 * @desc 수첩 헤더
 * menuChoice : 일지 - diary / 가계부 - wallet
 */
import { DIARY, WALLET } from 'constants/index';
import { useNavigate } from 'react-router-dom';
import { URL_DIARY, URL_WALLET } from 'routes/RouteUrl';
import { BaseHeaderAction } from './BaseHeaderAction';

export function BaseHeaderNote({ menuChoice }) {
    const navigate = useNavigate();

    return (
        <div className="header-tab">
            <button
                type="button"
                className={
                    'log-tab' && menuChoice === URL_DIARY ? 'active' : ''
                }
                onClick={() => {
                    navigate(`${URL_DIARY}`);
                }}
            >
                {DIARY}
            </button>
            <button
                type="button"
                className={
                    'account-tab' && menuChoice === URL_WALLET ? 'active' : ''
                }
                onClick={() => {
                    navigate(`${URL_WALLET}`);
                }}
            >
                {WALLET}
            </button>
        </div>
    );
}
