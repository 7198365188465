import { Popup } from 'antd-mobile';
import { IconClose } from 'components/assets/icon';

import { BaseImageButton, BaseButton } from 'components/elements';
import { BaseIconButton } from 'components/elements/BaseButton';
import { useEffect, useState } from 'react';
import { calc } from 'utils/utils';
import { BottomPopUp } from './BottomPopUp';

export const Calculator = ({
    show,
    mask = true,
    position,
    bodyStyle,
    defaultData = '0',
    onClick,
    onOk,
    onClose,
}) => {
    const [exp, setExp] = useState(defaultData); // 표현식

    const fnCalc = (value, operator) => {
        const retVal = exp;
        // console.log(retVal, value, operator)
        if (value) {
            if (retVal === 0) setExp(value);
            else setExp(retVal + value);
        }
        if (operator) {
            setExp(retVal + operator);
        }
    };

    const fnShowResult = () => {
        const result = calc(exp);
        setExp(result);
    };

    const fnClearOne = () => {
        const expString = exp.toString();
        if (expString.length > 1)
            setExp(expString.substring(0, expString.length - 1));
        else fnClear();
    };
    const fnClear = () => {
        setExp(0);
    };

    const fnClickItem = (value) => {
        if (onClick) onClick(value);
    };

    const fnHandleOk = () => {
        if (onOk) onOk(calc(exp));
    };

    const fnHandleClose = () => {
        if (onClose) onClose();
    };

    const fnHandleMaskClick = () => {
        if (onClose) onClose();
    };

    useEffect(() => {
        setExp(defaultData);
    }, [show]);

    return (
        // <Popup
        //   visible={show}
        //   mask={mask}
        //   position={position}
        //   bodyStyle={{
        //     ...bodyStyle,
        //     minHeight: '27rem',
        //   }}
        // >
        <BottomPopUp
            show={show}
            hasBottom={false}
            onMaskClick={fnHandleMaskClick}
        >
            <div className="popup-bottom">
                <div className="popup-wrap account-book-write-popup calculator  pb-34">
                    <div className="popup-head popup-close">
                        <div className="display align vm">
                            <div className="value">{exp}</div>
                        </div>
                        <BaseIconButton onClick={fnHandleClose}>
                            <IconClose />
                        </BaseIconButton>
                        {/* <strong>금액을 입력해 주세요.</strong>
              <BaseImageButton imageSrc={"/assets/images/icon-close.svg"} altText={"닫기"} onClick={fnHandleClose}/> */}
                    </div>
                    <div className="popup-content pb-16">
                        <div className="divider-01"></div>
                        <div className="box-choice-list calculator-wrap">
                            <ul>
                                <li>
                                    <div
                                        className="calculator-item calculation-A"
                                        onClick={() => fnCalc('', '÷')}
                                    >
                                        <button onClick={() => fnCalc('', '÷')}>
                                            ÷
                                        </button>
                                    </div>
                                    <div
                                        className="calculator-item calculation-A"
                                        onClick={() => fnCalc('', '×')}
                                    >
                                        <button onClick={() => fnCalc('', '×')}>
                                            ×
                                        </button>
                                    </div>
                                    <div
                                        className="calculator-item calculation-A"
                                        onClick={() => fnCalc('', '-')}
                                    >
                                        <button onClick={() => fnCalc('', '-')}>
                                            -
                                        </button>
                                    </div>
                                    <div
                                        className="calculator-item calculation-A"
                                        onClick={() => fnCalc('', '+')}
                                    >
                                        <button onClick={() => fnCalc('', '+')}>
                                            +
                                        </button>
                                    </div>

                                    <div
                                        className="calculator-item number"
                                        onClick={() => fnCalc('7', '')}
                                    >
                                        <button onClick={() => fnCalc('7', '')}>
                                            7
                                        </button>
                                    </div>
                                    <div
                                        className="calculator-item number"
                                        onClick={() => fnCalc('8', '')}
                                    >
                                        <button onClick={() => fnCalc('8', '')}>
                                            8
                                        </button>
                                    </div>
                                    <div
                                        className="calculator-item number"
                                        onClick={() => fnCalc('9', '')}
                                    >
                                        <button onClick={() => fnCalc('9', '')}>
                                            9
                                        </button>
                                    </div>
                                    <div
                                        className="calculator-item calculation-B"
                                        onClick={fnShowResult}
                                    >
                                        <button onClick={fnShowResult}>
                                            =
                                        </button>
                                    </div>

                                    <div
                                        className="calculator-item number"
                                        onClick={() => fnCalc('4', '')}
                                    >
                                        <button onClick={() => fnCalc('4', '')}>
                                            4
                                        </button>
                                    </div>
                                    <div
                                        className="calculator-item number"
                                        onClick={() => fnCalc('5', '')}
                                    >
                                        <button onClick={() => fnCalc('5', '')}>
                                            5
                                        </button>
                                    </div>
                                    <div
                                        className="calculator-item number"
                                        onClick={() => fnCalc('6', '')}
                                    >
                                        <button onClick={() => fnCalc('6', '')}>
                                            6
                                        </button>
                                    </div>
                                    <div
                                        className="calculator-item calculation-B"
                                        onClick={() => fnCalc('.', '')}
                                    >
                                        <button onClick={() => fnCalc('.', '')}>
                                            .
                                        </button>
                                    </div>

                                    <div
                                        className="calculator-item number"
                                        onClick={() => fnCalc('1', '')}
                                    >
                                        <button onClick={() => fnCalc('1', '')}>
                                            1
                                        </button>
                                    </div>
                                    <div
                                        className="calculator-item number"
                                        onClick={() => fnCalc('2', '')}
                                    >
                                        <button onClick={() => fnCalc('2', '')}>
                                            2
                                        </button>
                                    </div>
                                    <div
                                        className="calculator-item number"
                                        onClick={() => fnCalc('3', '')}
                                    >
                                        <button onClick={() => fnCalc('3', '')}>
                                            3
                                        </button>
                                    </div>
                                    <div
                                        className="calculator-item calculation-B"
                                        onClick={fnClearOne}
                                    >
                                        <button>
                                            <img
                                                src="/assets/images/icon-caculater.svg"
                                                alt="지우기"
                                            />
                                        </button>
                                    </div>

                                    <div
                                        className="calculator-item number"
                                        onClick={() => fnCalc('00', '')}
                                    >
                                        <button
                                            onClick={() => fnCalc('00', '')}
                                        >
                                            00
                                        </button>
                                    </div>
                                    <div
                                        className="calculator-item number"
                                        onClick={() => fnCalc('0', '')}
                                    >
                                        <button onClick={() => fnCalc('0', '')}>
                                            0
                                        </button>
                                    </div>
                                    <div
                                        className="calculator-item number"
                                        onClick={() => fnCalc('000', '')}
                                    >
                                        <button
                                            onClick={() => fnCalc('000', '')}
                                        >
                                            000
                                        </button>
                                    </div>
                                    <div className="calculator-item check">
                                        <BaseButton
                                            label={'확인'}
                                            onClick={fnHandleOk}
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* </Popup> */}
        </BottomPopUp>
    );
};
