import client from '../axios';
import {
    DELETE_COMMUNITY_DETAIL,
    DELETE_COMMUNITY_REPLY,
    GET_COMMUNITY_BLOCK_NEIGHBORS,
    GET_COMMUNITY_COMPLAINLIST,
    GET_COMMUNITY_DETAIL,
    GET_COMMUNITY_FORBIDDENLIST,
    GET_COMMUNITY_INFO,
    GET_COMMUNITY_LIKE,
    GET_COMMUNITY_MISSING_LIST,
    GET_COMMUNITY_MY_GOOD,
    GET_COMMUNITY_MY_NEIGHBORS,
    GET_COMMUNITY_MY_REPLY_GOOD,
    GET_COMMUNITY_MY_WRITE,
    POST_COMMUNITY_COMPLAIN,
    POST_COMMUNITY_REPLY,
    POST_COMMUNITY_WRITE,
    PUT_COMMUNITY_DETAIL,
    PUT_COMMUNITY_VIEW_CNT,
} from './endpoint';

export const postAddCommunityAPI = ({ data }) => {
    return client.post(POST_COMMUNITY_WRITE, data);
};

export const getCommunityInfoAPI = ({ params }) => {
    return client.get(GET_COMMUNITY_INFO.concat(`?${params?.join('&')}`));
};

export const getComplainListAPI = () => {
    return client.get(GET_COMMUNITY_COMPLAINLIST);
};

export const getLikeAPI = (idx, { params }) => {
    return client.get(GET_COMMUNITY_LIKE.replace(':idx', idx), { params });
};

export const postWriteReplyAPI = ({ data }) => {
    return client.post(POST_COMMUNITY_REPLY, data);
};

export const getCommunityDetailAPI = (idx, { params }) => {
    return client.get(GET_COMMUNITY_DETAIL.replace(':idx', idx), { params });
};

export const putCommunityUpdateAPI = ({ data }) => {
    return client.put(PUT_COMMUNITY_DETAIL, data);
};

export const deleteCommunityAPI = (idx) => {
    return client.delete(DELETE_COMMUNITY_DETAIL.replace(':idx', idx));
};

export const getForbiddenListAPI = () => {
    return client.get(GET_COMMUNITY_FORBIDDENLIST);
};

export const getMyWriteHistoryAPI = ({ params }) => {
    return client.get(GET_COMMUNITY_MY_WRITE, { params });
};

export const getMyLikeAPI = ({ params }) => {
    return client.get(GET_COMMUNITY_MY_GOOD, { params });
};

export const getMyNeighborsAPI = ({ params }) => {
    return client.get(GET_COMMUNITY_MY_NEIGHBORS, { params });
};

export const getBlockNeighborsAPI = ({ params }) => {
    return client.get(GET_COMMUNITY_BLOCK_NEIGHBORS, { params });
};

export const postComplainAPI = ({ data }) => {
    return client.post(POST_COMMUNITY_COMPLAIN, data);
};

export const getMissingListAPI = ({ params }) => {
    return client.get(
        GET_COMMUNITY_MISSING_LIST.concat(`?${params?.join('&')}`),
    );
};

export const deleteCommunityReplyAPI = (community_reply_idx) => {
    return client.delete(
        DELETE_COMMUNITY_REPLY.replace(
            ':community_reply_idx',
            community_reply_idx,
        ),
    );
};

export const getMyReplyLikeAPI = ({ params }) => {
    return client.get(GET_COMMUNITY_MY_REPLY_GOOD, { params });
};

export const putCommunityDetailViewCntAPI = ({ idx, userIdx }) => {
    return client.put(
        PUT_COMMUNITY_VIEW_CNT.replace(':idx', idx).replace(
            ':user_idx',
            userIdx,
        ),
    );
};
