import { useEffect } from 'react';

export function BaseFooterFix({
    className = '',
    children,
    fixPaddingBottom = 'wrap',
    baseOffset = 0,
}) {
    useEffect(() => {
        const mainElement = document.querySelector('main');

        if (!mainElement) return;

        const fixEl = mainElement.querySelector('.fix-bottom');
        const wrap = mainElement.querySelector('.' + fixPaddingBottom);

        if (!fixEl) return;

        const fixElHeight = fixEl.offsetHeight - baseOffset;
        wrap.style.paddingBottom = `${fixElHeight}px`;
    }, []);

    return (
        <div className={className ? `fix-bottom ${className}` : 'fix-bottom'}>
            {children}
        </div>
    );
}
