import { Map } from 'immutable';

export const TYPES = {
    SET_MAIN_DATA: 'SET_MAIN_DATA',
    GET_MAIN_DATA: 'GET_MAIN_DATA',
};

const initState = {
    mainData: {
        bannerList: [],
        eventList: [],
        homeList: [],
        popularList: [],
        selectList: [],
    },
};

export default function MainReducer(state = initState, action) {
    switch (action.type) {
        case TYPES.SET_MAIN_DATA:
            return {
                ...state,
                mainData: action.payload,
            };

        case TYPES.GET_MAIN_DATA:
            return state.mainData;

        default:
            return state;
    }
}

export const actions = {
    setMainData: (data) => ({ type: TYPES.SET_MAIN_DATA, payload: data }),
    getMainData: () => ({ type: TYPES.GET_MAIN_DATA }),
};
