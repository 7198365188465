import { useEffect, useState } from 'react';
import { DAYS_LABEL } from 'constants/Label';
import dayjs from 'dayjs';

/**
 * 요일
 * @returns
 */
function DayCell({ value }) {
    return (
        <div className="cmp-simple-calendar-day-wrap">
            <div className="cmp-simple-calendar-day-label">{value}</div>
        </div>
    );
}
/**
 * 일
 * @returns
 */
function DateCell({ today, selectedDay, value, onChange }) {
    const thisMonth = selectedDay ? selectedDay.slice(0, 6) : today.slice(0, 6);
    const month = value.slice(0, 6);
    const day = Number(value.slice(-2));

    //console.log(`thisMonth: ${thisMonth} month: ${month}`);

    let dateStyle = {};

    if (thisMonth !== month) {
        dateStyle = { ...dateStyle, color: 'var(--color-grayb-300)' };
    }

    if (value === today) {
        dateStyle = {
            ...dateStyle,
            color: 'var(--color-today-date)',
            backgroundColor: 'var(--bgcolor-today-date)',
        };
    }
    if (value === selectedDay) {
        dateStyle = {
            ...dateStyle,
            color: 'var(--color-white)',
            fontWeight: 'bold',
            backgroundColor: 'var(--color-main-500)',
        };
    }

    return (
        <div
            className="cmp-simple-calendar-date-wrap"
            onClick={() => onChange(value)}
        >
            <div
                className="cmp-simple-calendar-date-label modal-font-size"
                style={{ ...dateStyle }}
            >
                {day}
            </div>
        </div>
    );
}

export function SimpleCalendar({ defaultDate, onChange }) {
    const [today] = useState(dayjs().format('YYYYMMDD'));
    //const [selectedDay, setSelectedDay] = useState(defaultDate);
    const [dates, setDates] = useState([]);

    const getDates = () => {
        // 현재 달 첫째 날
        const firstDate = dayjs(defaultDate).startOf('month').day(0); // 0 (요일 - 일요일 : 0, 토요일 : 6)

        // 현재 달 마지막 날
        // const lastDate = moment(today).clone().endOf("month").day(6);

        const dateStack = [];

        let curDate = firstDate;

        // 6 week
        for (let i = 0; i < 42; i++) {
            dateStack.push(curDate.format('YYYYMMDD'));
            curDate = curDate.add(1, 'days');
        }

        // console.log(`firstDate : ${firstDate}`);
        // console.log(`lastDate : ${lastDate}`);
        //console.log(`dateStack : `, dateStack);

        setDates(dateStack);
    };

    const onChangeDate = (value) => {
        //setSelectedDay(value);
        if (onChange) onChange(value);
    };

    useEffect(() => {
        getDates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultDate]);

    return (
        <>
            <div className="cmp-simple-calendar-week day">
                {DAYS_LABEL?.map((value) => {
                    return <DayCell key={value} value={value} />;
                })}
            </div>
            <div className="cmp-simple-calendar-week date">
                {dates?.map((value) => {
                    return (
                        <DateCell
                            key={value}
                            today={today}
                            selectedDay={defaultDate}
                            value={value}
                            onChange={onChangeDate}
                        />
                    );
                })}
            </div>
        </>
    );
}
