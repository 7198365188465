import { createContext, useContext, useMemo, useState } from 'react';
import { useLocalStorage } from './useLocalStorage';
import { $set } from 'utils/mobile';
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useLocalStorage('persist:user', null);

    // call this function when you want to authenticate the user
    const login = async (data) => {
        if (
            navigator.userAgent.match(/pethome.ios/i) !== null ||
            window.pethome
        ) {
            $set({ key: 'userToken', data: data?.token });
            $set({ key: 'userIdx', data: data?.user_idx });
        }
        setIsAuthenticated(true);
        setUser(data);
    };

    // call this function to sign out logged in user
    const logout = () => {
        if (
            navigator.userAgent.match(/pethome.ios/i) !== null ||
            window.pethome
        ) {
            $set({ key: 'userToken', data: '' });
            $set({ key: 'userIdx', data: '' });
        }
        setUser(null);
        setIsAuthenticated(false);
        // window.localStorage.clear();
        // user 정보만 날리게 수정
        window.localStorage.removeItem('persist:user');
        window.sessionStorage.clear();
    };

    const value = useMemo(
        () => ({
            user,
            isAuthenticated,
            login,
            logout,
        }),
        [user],
    );
    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
