export function BottomRoundPopup({ title, content, buttonArea, onMaskClick }) {
    const fnHandleMaskClick = () => {
        if (onMaskClick) onMaskClick();
    };

    return (
        <div className="adm-mask" onClick={fnHandleMaskClick}>
            <div className="popup-list popup-bottom-round">
                <div className="popup-wrap">
                    {title && (
                        <div className="popup-head level">
                            <p>
                                <img
                                    src="/assets/images/icon-question.svg"
                                    alt=""
                                />
                                <strong>{title}</strong>
                            </p>
                        </div>
                    )}
                    {content && <div className="popup-content">{content}</div>}
                    {buttonArea && (
                        <div className="popup-button-wrap">{buttonArea}</div>
                    )}
                </div>
            </div>
        </div>
    );
}
